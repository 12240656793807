<template>
  <div v-if="isHaveAccess" class="pl-2 pr-2 h-max-content">
    <b-overlay :show="isLoading" rounded="sm" class="mh-screen">
      <h1>Sklad</h1>

    </b-overlay>

  </div>
</template>


<script>


export default {
  components: {},
  data() {
    return {
      isLoading: false,
    };
  },
  computed: {
    isHaveAccess() {
      return this.$store.getters.isSkladPageAllowed;
    },
  },
  methods: {
    async getInfo() {
      try {
        // const url = `/user/getUsersActivities`;
        // const res = await Axios.post(url, {dateFrom: getStartDay(this.dateFrom), dateTo: getEndDay(this.dateTo)});
        // const {data} = res;
        // this.users = data.data.users;
      } catch (e) {
        console.log('getInfo Sklad', e)
      }
    },
  },
  async mounted() {
    this.isLoading = true;
    await this.getInfo();
    this.isLoading = false;
  },
};
</script>

<style scoped>

</style>
